// src/pages/ReturnPolicyPage.js
import React from 'react';
import Navbar from './navbar'
import Footer from './footer';
const ReturnPolicyPage = () => {
  return (
    <>
      <Navbar />
     
   <div className="return-policy-container">
      <h1>Return Policy</h1>
      <p><strong>Effective Date:</strong> Jan 1st 2024</p>
      
      <p>Welcome to AmberSpace's Return Policy page. We are committed to ensuring that our customers are satisfied with their purchases. Please read the following policy to understand your rights and the steps for returns and exchanges.</p>
      
      <h2>Returns</h2>
      <p><strong>Eligible Returns:</strong> We accept returns for defective products only. If you receive a product that is damaged, faulty, or not as described, please contact our customer support team to initiate a return.</p>
      
      <p><strong>Return Procedure:</strong></p>
      <ol>
        <li><strong>Contact Us:</strong> Reach out to our customer support team at <a href="mailto:support@amberspace.co.ke">support@amberspace.co.ke</a> within 5 days of receiving your product. Provide your order number and details of the issue.</li>
        <li><strong>Return Authorization:</strong> Our team will provide you with a Return Authorization Number (RAN) and instructions on how to return the product.</li>
        <li><strong>Return Shipping:</strong> Pack the defective product securely and send it back to us using the shipping method provided in the return instructions. You are responsible for return shipping costs unless the product was defective upon receipt.</li>
        <li><strong>Inspection:</strong> Once we receive the returned product, our team will inspect it and process your return. If the product is deemed defective, we will issue a replacement or refund.</li>
      </ol>
      
      <p><strong>Non-Returnable Items:</strong> Products that have been used, altered, or are missing original packaging may not be eligible for a return.</p>
      
      <h2>Exchanges</h2>
      <p><strong>Eligible Exchanges:</strong> We accept exchanges for products that are defective. If you would like to exchange a defective product for a different item, please follow the same return procedure and specify the item you wish to receive in exchange.</p>
      
      <p><strong>Exchange Procedure:</strong></p>
      <ol>
        <li><strong>Contact Us:</strong> Reach out to our customer support team at <a href="mailto:support@amberspace.co.ke">support@amberspace.co.ke</a> within 5 days of receiving your product. Provide your order number and details of the issue.</li>
        <li><strong>Exchange Authorization:</strong> Our team will provide you with Exchange Authorization Number (EAN) and instructions on how to return the product and receive the new item.</li>
        <li><strong>Return Shipping:</strong> Pack the defective product securely and send it back to us using the shipping method provided in the exchange instructions. You are responsible for return shipping costs unless the product was defective upon receipt.</li>
        <li><strong>Replacement:</strong> Once we receive the returned product, we will process your exchange and send the replacement item to you.</li>
      </ol>
      
      <p><strong>Note:</strong> Exchanges are subject to product availability. If the item you wish to exchange for is out of stock, we will offer a refund instead.</p>
      
      <h2>Contact Us</h2>
      <p>If you have any questions or need further assistance with your return or exchange, please contact us at:</p>
      <ul>
        <li><strong>Email:</strong> <a href="mailto:support@amberspace.co.ke">support@amberspace.co.ke</a></li>
        <li><strong>Phone:</strong> +254101165327</li>
        <li><strong>Address:</strong> Nairobi, Kenya</li>
      </ul>
      
      <p>Thank you for shopping with AmberSpace. We appreciate your business!</p>
    </div>  
    <Footer/>
    </>
   
  );
};

export default ReturnPolicyPage;
