import React, { useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { IconButton, Typography } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import { useNavigate } from 'react-router-dom';
export const options = [
  { label: "Computers and Laptops", iconClass: "fas fa-tv",
   
  },
  { label: "Dell", iconClass: "fas fa-tv",
 
  },
  { label: "HP", iconClass: "fas fa-volume-up",
   
  },
  { label: "Lenovo", iconClass: "fas fa-snowflake",

  },
  { label: "Monitors", iconClass: "fas fa-tv",
 
  },
  { label: "Printers", iconClass: "fas fa-tshirt",

  },
 
  { label: "UPS Backups", iconClass: "fas fa-blender",
 
  },
  { label: "Projectors", iconClass: "fas fa-building",
    children: [
      { title: 'Ovens', children: [] },
      { title: 'Hobs', children: [] },
      { title: 'Microwaves', children: [] },
      { title: 'Hoods', children: [] },
      { title: 'Dishwashers', children: [] },
      { title: 'Fridges & Freezers', children: [] },
      { title: 'Warmer Drawer', children: [] },
    ],
  },


  { label: "Phones & Tablets", iconClass: "fas fa-mobile-alt",
 
  },
 


];
const menuItemStyle = {
  display: 'flex',
  alignItems: 'center', // Center icon and text vertically
};

const iconStyle = {
  marginRight: '10px', // Add spacing between icon and text
};

export default function App() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(1);
  const open = Boolean(anchorEl);

  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
const navigate=useNavigate()
  return (
    <div>
      <IconButton onClick={() => setDrawerOpen(true)} sx={{ color: 'black' }}>
        <i className="fas fa-bars"></i>
      </IconButton>

      <Drawer open={drawerOpen} onClose={() => setDrawerOpen(false)}>
        <div>
        <Typography variant="h6" style={{paddingLeft:10}}>Categories</Typography>
          <List>
            {options.map((option, index) => (
              <ListItem
                button
                key={option.label}
                selected={index === selectedIndex}
                onClick={(event) => {navigate('/category?search='+option.label.split(' ')[0]);     window.location.reload()}}
                style={menuItemStyle}
              >
                {/* <i className={option.iconClass} style={iconStyle}></i>  */}
                {option.label}
              </ListItem>
            ))}
          </List>
        </div>
      </Drawer>

      <main>
        {/* Your main content goes here */}
      </main>
    </div>
  );
}
