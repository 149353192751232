import React, { useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { IconButton, Typography } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { red } from '@mui/material/colors';
import { useNavigate } from 'react-router-dom';

export const options = [
  { label: "Computers and Laptops", iconClass: "fas fa-tv",
   
  },
  { label: "Dell", iconClass: "fas fa-tv",
 
  },
  { label: "HP", iconClass: "fas fa-volume-up",
   
  },
  { label: "Lenovo", iconClass: "fas fa-snowflake",

  },
  { label: "Monitors", iconClass: "fas fa-tv",
 
  },
  { label: "Printers", iconClass: "fas fa-tshirt",

  },
 
  { label: "UPS Backups", iconClass: "fas fa-blender",
 
  },
  { label: "Projectors", iconClass: "fas fa-building",
    children: [
      { title: 'Ovens', children: [] },
      { title: 'Hobs', children: [] },
      { title: 'Microwaves', children: [] },
      { title: 'Hoods', children: [] },
      { title: 'Dishwashers', children: [] },
      { title: 'Fridges & Freezers', children: [] },
      { title: 'Warmer Drawer', children: [] },
    ],
  },


  { label: "Phones & Tablets", iconClass: "fas fa-mobile-alt",
 
  },
 


];
const menuItemStyle = {
  display: 'flex',
  position: 'relative',
  alignItems: 'center', // Center icon and text vertically
  cursor: 'pointer', // Add pointer cursor
  padding: '8px', // Add padding for better spacing
  transition: 'background-color 0.3s', // Smooth transition for background color
};

const iconStyle = {
  marginRight: '10px', // Add spacing between icon and text
};

const subMenuIconStyle = {
  marginLeft: 'auto', // Push the forward icon to the right
};

const subMenuStyle = {
  width: '300px', // Adjust the submenu width as needed
  backgroundColor: 'white',
  color:'black',
  padding:10,
  top: 0,
  right: '-320px', // Hide the submenu by default
  position: 'absolute',
  boxShadow: '0 1px 1.5px 0 rgba(0,0,0,.12), 0 1px 1px 0 rgba(0,0,0,.24)',
  zIndex: 1000,
  
  // Make sure it's on top of other elements
  // transition: 'right 0.3s', // Add a smooth transition for showing/hiding
};

export default function App() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(1);
  const [hoverIndex, setHoverIndex] = useState(1);
  const open = Boolean(anchorEl);

  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMouseEnter = (index) => {
    // Show the submenu on hover
    setDrawerOpen(true);
    setHoverIndex(index)
  };

  const handleMouseLeave = () => {
    // Hide the submenu when the mouse leaves
    setDrawerOpen(false);
  };
const navigate=useNavigate()
  return (
    <div className='bannerMain'>
      <div className='categoryBanner'>
      <div className='catMenu largeDevice'>
          <List>
            {options.map((option, index) => (
              <div key={option.label}>
                <ListItem
                  button
                  key={option.label}
                  selected={index === selectedIndex}
                  onClick={(event) =>{
                    navigate('/category?search='+option.label.split(' ')[0])
                    // handleMenuItemClick(event, index)
                    window.location.reload()
                  }}
                  onMouseEnter={()=>handleMouseEnter(index)}
                  onMouseLeave={handleMouseLeave}
                  style={menuItemStyle}
                  sx={{
                    '&:hover': {
                      color: 'white',
                      background: 'linear-gradient(45deg, #FF512F 30%, #DD2476 90%)',
                    },
                  }}
                >
                   {option.label}
          
                </ListItem>
              
              </div>
            ))}
          </List>
        </div> 
        <div style={{ flex: 1,display:'flex',alignItems:'center',justifyContent:'space-between',flexDirection:'row' ,flexWrap:'wrap'}}>
        <div style={{minWidth:350,flex:3,backgroundColor:'transparent',display:'flex',flexDirection:"column"}}>
<div  class='banner-item'  style={{height:'65%',minHeight:350,backgroundImage: 'url(https://i.ytimg.com/vi/uQIs0goYkz0/hq720.jpg?sqp=-oaymwEhCK4FEIIDSFryq4qpAxMIARUAAAAAGAElAADIQj0AgKJD&rs=AOn4CLC9kF5fqM1T5wdroroEn1jmXsawHg)',backgroundPosition: 'center center', backgroundSize: 'cover', backgroundRepeat: "no-repeat" ,margin:2,borderRadius:15,position:'relative',overflow:'hidden'}}>

<div style={{position:'absolute',maxWidth:'70%',borderRadius:8,padding:10,right:10,bottom:10,backgroundColor:'rgba(222, 38, 114,0.8)'}}>
    <Typography variant='h7' style={{fontWeight:'bold',color:'white'}}>GET THE BEST DEALS WITH AMBERSPACE!</Typography><br/>
    <Typography variant='title' style={{fontWeight:'normal',color:'white'}}>we offer the best deals in all types of products, begin shopping today!</Typography>
 
  </div>
</div>
<div style={{flex:1,backgroundColor:'transparent',display:'flex'}}>


  
<div  class='banner-item' style={{minHeight:150,backgroundImage: 'url(https://kaizenaire.com/wp-content/uploads/2024/07/JX-LED-Projector-Promo-Free-Marketing-Promotional-Videos.jpg)',backgroundPosition: 'center center', backgroundSize: 'cover', backgroundRepeat: "no-repeat",width:'35%',margin:2,minWidth:100,borderRadius:15,position:'relative',overflow:'hidden'}}>

<div style={{position:'absolute',padding:10,left:0,bottom:0,width:'100%',backgroundColor:'rgba(0,0,0,0.7)'}}>
    <Typography variant='h7' style={{fontWeight:'normal',color:'white'}}>PROJECTORS</Typography>
  </div>
</div>
<div  class='banner-item'  style={{minHeight:150,backgroundImage: 'url(https://i.pinimg.com/originals/07/b4/27/07b427c6bfabfb5d663efaaf05392b0c.png)',backgroundPosition: 'center center', backgroundSize: 'cover', backgroundRepeat: "no-repeat",flex:1,margin:2,minWidth:100,borderRadius:15,position:'relative',overflow:'hidden'}}>\


<div style={{position:'absolute',padding:10,left:0,bottom:0,width:'100%',backgroundColor:'rgba(0,0,0,0.7)'}}>
    <Typography variant='h7' style={{fontWeight:'normal',color:'white'}}>MOBILE PHONES</Typography>
  </div>
</div>

</div>
        </div>
        <div style={{flex:2,minWidth:350,backgroundColor:'transparent',display:'flex',flexDirection:"column"}}>
<div class='banner-item' style={{flex:1,minHeight:250,overflow:'hidden',backgroundImage: 'url(https://m.media-amazon.com/images/I/714NlomeavL._AC_UF894,1000_QL80_.jpg)',backgroundPosition: 'center center', backgroundSize: 'cover', backgroundRepeat: "no-repeat",margin:2,borderRadius:15,position:'relative'}}>
<div style={{position:'absolute',padding:10,left:0,bottom:0,width:'100%',backgroundColor:'rgba(0,0,0,0.7)'}}>
    <Typography variant='h7' style={{fontWeight:'normal',color:'white'}}> COMPUTER</Typography>
  </div>
</div>
<div   class='banner-item' style={{flex:1,minHeight:250,overflow:'hidden',backgroundImage: 'url(https://pro.epson.asia/forms/hybris/revamp-page/images/where-to-buy/singapore/IWP-BeCool_MyEpson_Portal_0624_510x310.jpg)',backgroundPosition: 'center center', backgroundSize: 'cover', backgroundRepeat: "no-repeat",margin:2,borderRadius:15,position:'relative'}}>
<div style={{position:'absolute',padding:10,left:0,bottom:0,width:'100%',backgroundColor:'rgba(0,0,0,0.7)'}}>
    <Typography variant='h7' style={{fontWeight:'normal',color:'white'}}>Printers</Typography>
  </div>

</div>
        </div>
        </div>
      </div>
    </div>
  );
}
