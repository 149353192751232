import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { IconButton, Typography } from '@mui/material';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';


import { Autoplay,FreeMode, Navigation, Pagination } from 'swiper/modules';
import { Link } from 'react-router-dom';

export default function App(props) {
  return (
    <>

      <Swiper
           slidesPerView={props.screenWidth<600 ?3:5}
     spaceBetween={20}
 
     freeMode={true}
    
     autoplay={{
       delay: 2500,
       disableOnInteraction: false,
     }}
     pagination={{
       clickable: true,
     }}
     navigation={false}
     modules={[Autoplay,FreeMode, Navigation]}
     
      >
        {/* <div style={{width:'100%',padding:5}}><Typography variant="h6">Top Products</Typography></div> */}
     {props.topProducts.map(item=>



  <SwiperSlide key={item.id}>

    <Link to={`../../product/${item.id}/${item.name.trim().replaceAll("-", " ").replaceAll(/\s+/g, " ").replaceAll(" ", "-").split('/')[0]}`} style={{ width: 180,position:"relative",textDecoration:"none", backgroundColor: 'white', borderRadius: 10, margin: 5, padding: 10, textAlign: 'center' }} className='hover2-div'>
    {((item.originalPrice - item.price) / item.originalPrice * 100) >= 1 && <div style={{ padding: 3,opacity:0.7, fontSize: 12, borderRadius: 10, backgroundColor: 'red', color: 'white', opacity: 0.8, fontWeight: 'bold', position: 'absolute', top: 30, right: 5, zIndex: 10 }}>
      {((item.originalPrice - item.price) / item.originalPrice * 100).toFixed(2)}%
    </div>}
 {/* <div style={{ width: 150, backgroundColor: 'white', borderRadius: 10, margin: 5, padding: 10, textAlign: 'center' }} className='hover2-div'> */}
   <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 150, overflow: 'hidden' }}>
     <img src={item?.images[0] ?? ''} style={{ width: '100%', objectFit: 'contain' }} alt={item.name} />
   </div>
   <Typography style={{ fontSize: 14, lineHeight: '18px', fontWeight: 'bolder', padding: 3,color:'black',fontSize:13 }}>{item.name}</Typography>
   <Typography style={{ color: 'green', fontWeight: 'bold', fontSize: 14 }}>
     Ksh {item.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
   </Typography>
 {/* </div> */}
 </Link>
</SwiperSlide>

 
     )}   
 


      </Swiper>
    </>
  );
}
