import React,{useState,useEffect,useLayoutEffect} from 'react';
import {
  Container,
  TextField,
  Button,
  Grid,
  Typography,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Box,
  Alert,
  CircularProgress,
  Card,
  CardMedia,
  IconButton,
  CardContent,
  Autocomplete,
  Chip
} from '@mui/material'; 
import { mkConfig, generateCsv, download } from "export-to-csv";
 import brands from './brands.json';
import { Close, PhotoCamera } from '@mui/icons-material';
import  { db, storage } from './firebase-config'
import {limit,startAfter,onSnapshot, collection,getDocs,query,doc,where,addDoc,deleteDoc,updateDoc, orderBy, startAt, endAt } from '@firebase/firestore'
import { ref, uploadBytes, getDownloadURL, deleteObject  } from '@firebase/storage';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Rating } from '@mui/material';
import categoriesData2 from './categories.json';
// import category from './category';
import ReactHtmlParser from 'react-html-parser'
import { Dialog, DialogTitle, DialogContent, DialogActions,   List, ListItem, ListItemText } from '@mui/material';
import CategoryModal from './categorylisting';
import { useNavigate } from 'react-router-dom';
import OrdersPage from './orders';
import BrandSimpleTree from './brandlisting';

const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};
const ProductForm = () => {
  const [isCategoryModalOpen, setIsCategoryModalOpen] = useState(false);
  const [isBrandModalOpen, setIsBrandModalOpen] = useState(false);
const navigate=useNavigate()
  const handleOpenCategoryModal = () => {
    setIsCategoryModalOpen(true);
  };

  const handleCloseCategoryModal = () => {
    setIsCategoryModalOpen(false);
  };
  const handleOpenBrandModal = () => {
    setIsBrandModalOpen(true);
  };

  const handleCloseBrandModal = () => {
    setIsBrandModalOpen(false);
  };
    const [success,setSuccess]=useState('')
  const [product, setProduct] = useState({
    name: '',
    description: '',
    size: '',
    color: '',
    quantity: '',
    price: '',
    brand:'',
    otherDetail:'',
    category: '',
    originalPrice:'',
    status:'',
    
images:[]
  });
  
  const [error, setError] = useState(null);
  const [imagePreviews, setImagePreviews] = useState([]);

  const [images, setImages] = useState([]);
  const [uploadedImages, setUploadedImages] = useState([]);
const [categoriesData,setCategoriesData]= useState([]);
const [brandsData,setBrandsData]= useState([]);
  const handleImageChange = (e) => {
   
   const files=images.concat(Array.from(e.target.files));
    setImages(files);

    const previewUrls = [];
    files.forEach(file => {
      const reader = new FileReader();
      reader.onload = () => {
        previewUrls.push(reader.result);
        setImagePreviews([...previewUrls]);
      };
      reader.readAsDataURL(file);
    });
  };

  const removeImage = (index) => {
    const updatedPreviews = [...imagePreviews];
    updatedPreviews.splice(index, 1);
    setImagePreviews(updatedPreviews);

    const updatedFiles = [...product.images];
    updatedFiles.splice(index, 1);
    setProduct({ ...product, images: updatedFiles });
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setProduct({ ...product, [name]: value });
  };



  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission
    console.log(product);
    if(edit==null){
         postProduct();
    }else{
        updateProduct();
    }
   
  };
  const [products,setProducts]=useState([])
  const [productList, setProductList] = useState([]);
  const [lastVisible, setLastVisible] = useState(null);
  const [loading, setLoading] = useState(false);
  const productsPerPage = 20;
  const clearData=()=>{
    setProduct({
        name: '',
        description: '',
        size: '',
        color: '',
        quantity: '',
        brand:'',
        price: '',
        otherDetail:'',
        category: '',
        originalPrice:'',
        status:'',
    images:[]
      });
      setEdit(null)
      setSuccess('')
      setUploadedImages([])
      setImagePreviews([])
      setImages([])
  }
const postProduct=async ()=>{
    try {
        
        setLoading(true);
        const imageUrls = await Promise.all(
            images.map(async (file, index) => {
                console.log(file)
              const storageRef = ref(storage, `products/${Date.now()}_${index}_${file.name}`);
              await uploadBytes(storageRef, file);
              const downloadURL = await getDownloadURL(storageRef);
              return downloadURL;
            })
          );
        console.log(imageUrls)
        setSuccess('')
              await addDoc(collection(db, 'products'),{...product,images:imageUrls,timestamp: new Date() })
            setSuccess('true')
            setTimeout(()=>{  setSuccess('')},3000)
            getProducts();
            // setIsFeatured(list[0].feature)
            // setIsRecommended(list[0].recommended)
              // onClose()
              clearData();
              setLoading(false);
            } catch (err) {
                setLoading(false);
                setSuccess('false')
             
              console.log(err)
            }
}
const updateProduct=async()=>{
    try {
        
        setLoading(true);
        const imageUrls = await Promise.all(
            images.map(async (file, index) => {
                console.log(file)
              const storageRef = ref(storage, `products/${Date.now()}_${index}_${file.name}`);
              await uploadBytes(storageRef, file);
              const downloadURL = await getDownloadURL(storageRef);
              return downloadURL;
            })
          );
        console.log(imageUrls)
        setSuccess('')
        const productDoc = doc(db, 'products', product.id);
       var temp={...product};
       var temp2=uploadedImages.concat(imageUrls);
       
       delete temp.id
        await updateDoc(productDoc, {
...temp,
          ['images']: temp2
        });
            setSuccess('true')
            setTimeout(()=>{  setSuccess('')},3000)
            getProducts();

            // setIsFeatured(list[0].feature)
            // setIsRecommended(list[0].recommended)
              // onClose()
              clearData();
              window.location.reload();
              setLoading(false);
            } catch (err) {
                setLoading(false);
                setSuccess('false')
             
              console.log(err)
            }
 
}
const [cat,setCat]=useState('')
const getProducts = async (loadMore = false) => {
  setLoading(true);
  let productQuery;

  if (loadMore && lastVisible) {
    productQuery = query(
      collection(db, 'products'),
      startAfter(lastVisible),
      limit(productsPerPage)
    );
  } else {
    productQuery = query(collection(db, 'products'), limit(productsPerPage));
  }

  const data = await getDocs(productQuery);
  const products = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
  const shuffledProducts = shuffleArray(products);
console.log(products)
  if (loadMore) {
    setProducts((prev) => [...prev, ...shuffledProducts]);
  } else {
    setProducts(shuffledProducts);
  }
  setLoading(false);
  setLastVisible(data.docs[data.docs.length - 1]);
}
useEffect(()=>{
    getProducts();
},[])
const loadMoreProducts = () => {
  getProducts(true);
};
const handleDelete = async (id,urlArray) => {
  let confirmDelete = confirm("Are you sure you want to delete this item?");

  if (confirmDelete) {
 try {
        console.log(urlArray)
      // Delete document from Firestore
      await deleteDoc(doc(db, 'products', id));

      // Delete images from Firebase Storage
      if(typeof urlArray!=undefined && urlArray!=null ){
urlArray.forEach(async (url) => {
        const imageRef = ref(storage, url);
        await deleteObject(imageRef);
        
      });
      }
      
getProducts();
      console.log('Product and images deleted successfully');
    } catch (error) {
      console.error('Error deleting product:', error);
    }
  } else {

    console.log("Delete operation canceled");
    }
       
  };
  const  [edit,setEdit]=useState(null)


  const [productName, setProductName] = useState('');
  const [productCategory, setProductCategory] = useState('');
//   const [searchResults, setSearchResults] = useState([]);

  const handleSearch = async () => {
    try {
      const productsRef = collection(db, 'products');
      let q;
console.log(productName,productCategory)
     
if (productName && productCategory) {
    q = query(
      productsRef,
      where('category', '==', productCategory),
      orderBy('name'),
      startAt(productName),
      endAt(productName + '\uf8ff')
    );
  } else if (productName) {


       q = query(productsRef);
      const data = await getDocs(q);
      var results = [];
      const regex = new RegExp(productName, 'i');

      data.docs.forEach((doc) => {
        const product = doc.data();
        if (regex.test(product.name)) {
          results.push({ id: doc.id, ...product });
        }
      });

    //   setSearchResults(results);
setProducts(results)
  } else if (productCategory) {
    q = query(productsRef, where('category', '==', productCategory));
  } else {
    q = query(productsRef);
  }

    //   const querySnapshot = await getDocs(q);
    //   const results = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    //   setProducts(results);
      console.log(results)
    } catch (error) {
      console.error('Error searching products:', error);
    }
  };
  const [modalOpen, setModalOpen] = useState(false);
  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };
  const [showNested,setShowNested]=useState(true)
  const [selectedId,setSelectedId]=useState(null)
  const [page,setPage]=useState(1)

  const exportCSV=()=>{
    const csvConfig = mkConfig({ useKeysAsHeaders: true,filename:'products'  });

    const mockData = products.map((obj,i) => { return {  
      'id': "prod_" + obj.id,
      'title': obj.name,
      'description': obj.name,
      'price': obj.price + ' KES',
      'availability': 'in_stock',
      'link': 'https://amberspace.co.ke/product/' + obj.id,
      'image_link': obj.images[0], // Main image
      'additional_image_link': obj.images?.slice(1).join(','), // Aditional images
      'condition': 'new'
      };
  })

    
    // Converts your Array<Object> to a CsvOutput string based on the configs
    const csv = generateCsv(csvConfig)(mockData);
    
    
    download(csvConfig)(csv)
  }
  useEffect(() => {
    const categoriesRef = doc(db, 'categories', 'categoriesDoc');
    const unsubscribe = onSnapshot(categoriesRef, (doc) => {
      if (doc.exists()) {
        setCategoriesData(doc.data()?.categories || []);
      } else {
        setCategoriesData([]);
      }

      setLoading(false);
    });

    return () => unsubscribe();
  }, []);
  

  useEffect(() => {
    const brandsRef = doc(db, 'brands', 'brandsDoc');
    const unsubscribe = onSnapshot(brandsRef, (doc) => {
      if (doc.exists()) {
        setBrandsData(doc.data().brands.map(item=>item.name)|| []);
      } else {
        setBrandsData([]);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);
  const [inputValue, setInputValue] = useState('');


  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
  };
  return (

     <>   
     <div style={{width:'100vw',backgroundColor:'#f8f8f8',display:"flex",alignItems:"center"}}> <font onClick={()=>{navigate('/')}} className="" style={{display:'flex',margin:10,alignItems:'center'}}> 
    <img src='/assets/images/logo.png' height='40px'/> 
       Amberspace </font> 
           <Typography onClick={()=>{setPage(1)}} style={{margin:10,color:page==1?'blue':'black',cursor:"pointer"}}>Products</Typography>
       <Typography onClick={()=>{setPage(2)}} style={{margin:10,color:page==2?'blue':'black',cursor:"pointer"}}>Orders</Typography>
  
       </div>
     {page==2 ?
 <><OrdersPage/></>:<>
    <div style={{display:'flex',flexWrap:"wrap",width:'100vw'}}>
      
        <div style={{flex:2,padding:20,minWidth:350}}>
          
        <Typography variant="h5" component="h1" gutterBottom>
      {edit==null ? "Add New Product":"Update Product"}
      {/* <Button variant="contained" color="primary" onClick={handleOpenCategoryModal}>
        Manage Categories
      </Button> */}
      <div style={{display:'flex'}}>
    <CategoryModal open={isCategoryModalOpen} handleClose={handleCloseCategoryModal} /><div style={{width:10}}></div>
    <BrandSimpleTree open={isBrandModalOpen} handleClose={handleCloseBrandModal} /> 

      </div>

      </Typography>
      {success=='true' && <Alert color='success' style={{marginBottom:10}}>Product posted</Alert>}
      {success=='false' && <Alert color='error' style={{marginBottom:10}}>Product not posted </Alert>}
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
            size='small'
              fullWidth
              label="Product Name"
              name="name"
              value={product.name}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12}  >
          <div style={{width:'100%',maxHeight:300,overflow:'auto'}}>
          <CKEditor
        editor={ ClassicEditor }
        data={product.description}
        onReady={ ( editor ) => {
          console.log( "CKEditor5 React Component is ready to use!", editor );
        } }
   
        onChange={ ( event, editor ) => {
          const data = editor.getData();
        //   console.log( { event, editor, data } );
        setProduct({ ...product, ['description']: data });
        } }
     
    
      /></div>
            {/* <TextField
            size='small'
              fullWidth
              label="Description"
              name="description"
              value={product.description}
              onChange={handleChange}
              multiline
              rows={3}
              required
            /> */}
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              size='small'
              label="Size"
              name="size"
              value={product.size}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
            size='small'
              fullWidth
              label="Color"
              name="color"
              value={product.color}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
{/* {product.brand} */}
<Autocomplete
              options={brandsData}
              getOptionLabel={(option) => option}
              inputValue={product.brand}
              // onInputChange={handleInputChange}
              renderInput={(params) => (
                <TextField
                {...params}
                
                  size='small'
                  label="Brand"
                  name="brand"
                  value={product.brand}
                  onChange={handleChange}
                  fullWidth
                />
              )}
              onChange={(event, value) => setProduct({ ...product, brand: value ?? '' })}
    
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
            size='small'
              fullWidth
              label="Quantity"
              name="quantity"
              type="number"
              value={product.quantity}
              onChange={handleChange}
              required
            />
          </Grid>
         
          <Grid item xs={6}>
     
            <TextField
            size='small'
              fullWidth
              label="Original Price"
              name="originalPrice"
              type="number"
              value={product.originalPrice}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
            size='small'
              fullWidth
              label="Price"
              name="price"
              type="number"
              value={product.price}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12}>
       
             <div style={{width:'100%',maxHeight:300,overflow:'auto'}}>
          <CKEditor
        editor={ ClassicEditor }
        data={product.otherDetail}
        onReady={ ( editor ) => {
          // console.log( "CKEditor5 React Component is ready to use!", editor );
        } }
   
        onChange={ ( event, editor ) => {
          const data = editor.getData();
        //   console.log( { event, editor, data } );
        setProduct({ ...product, ['otherDetail']: data });
        } }
     
    
      /></div>
          </Grid>
          <Grid item xs={12}>
            {/* <FormControl fullWidth required> */}
            {cat} 
            {product.name!='' && showNested && <NestedSelect product={product} category={product.category} setValue={(t)=>{ setProduct({ ...product, ['category']: t });}} categories={categoriesData} />}
           
             {/* </FormControl> */}
            
          </Grid>
          <Grid item xs={12}>
  <Select
    size="small"
    fullWidth
    name="status"
    value={product.status}
    onChange={handleChange}
    displayEmpty
  >
    <MenuItem value="" disabled>
      Product Status
    </MenuItem>
    <MenuItem value="New">New</MenuItem>
    <MenuItem value="EX-UK">EX-UK</MenuItem>
    <MenuItem value="Refubished">Refubished</MenuItem>
  </Select>
</Grid>

        
          <Grid item xs={12}>
            <Button
            size='small'
              variant="contained"
              component="label"

              startIcon={<PhotoCamera />}
            >
              Upload Images
              <input
                type="file"
                hidden
                multiple
                accept="image/*"
                onChange={handleImageChange}
              />
            </Button>
            <Box mt={2}>
              {product?.images?.length > 0 && (
                <Typography>
                  {product?.images?.length} image(s) selected
                </Typography>
              )}
            </Box>
          
          </Grid>
          <Grid item xs={12} display={'flex'} justifyContent={'end'}>
            { edit==null ?  <Button type="Create" variant="contained" color="primary" disabled={loading}>
              {loading ? <CircularProgress size={24} /> : 'Submit'}
            </Button>:
            <div>
                  <Button variant="outlined" color="primary" onClick={()=>{setEdit(null);clearData()}}>
             Cancel
            </Button>
            {' '}
            <Button type="submit" variant="contained" color="primary" disabled={loading}>
              {loading ? <CircularProgress size={24} /> : 'Update'}
            </Button>
            </div>
           
        }
         
          </Grid>
          <Box ml={2} display="flex"  gap={1} style={{width:"100%",overflow:"auto",marginTop:5 }}>
          {uploadedImages.map((src, index) => (
                  <Card key={index} variant="outlined" sx={{ maxWidth: 100,position:'relative'}}>
                    <CardMedia
                      component="img"
                      image={src}
                      alt={`preview ${index}`}
                      sx={{ height: 70,minWidth:70, objectFit: 'contain' }}
                    />
                
                      <IconButton
                        aria-label="delete"
                        onClick={async () => {
                            let confirmDelete = confirm("Are you sure you want to delete this item?");

if (confirmDelete) {

    try { const updatedPreviews = [...uploadedImages];
        updatedPreviews.splice(index, 1);
        setUploadedImages(updatedPreviews);
        const productDoc = doc(db, 'products', product.id);
        delete productDoc.id;
        await updateDoc(productDoc, {
          ['images']: updatedPreviews
        });
     const imageRef = ref(storage, src);
            await deleteObject(imageRef);
            
    }catch(e){
            console.log(e)
        }
        
} else {
  
  console.log("Delete operation canceled");
}
                           
                        }}
                        size="small"
                       
                        style={{position:'absolute',right:5,top:5,fontColor:"black",backgroundColor:"white"}}
                      >
                        <Close />
                      </IconButton>
                    
                  </Card>
                ))}
                {imagePreviews.map((src, index) => (
                  <Card key={index} variant="outlined" sx={{ maxWidth: 100,position:'relative'}}>
                    <CardMedia
                      component="img"
                      image={src}
                      alt={`preview ${index}`}
                      sx={{ height: 70,minWidth:70, objectFit: 'contain' }}
                    />
                
                      <IconButton
                        aria-label="delete"
                        onClick={() => removeImage(index)}
                        size="small"
                       
                        style={{position:'absolute',right:5,top:5,fontColor:"black",backgroundColor:"white"}}
                      >
                        <Close />
                      </IconButton>
                    
                  </Card>
                ))}
              </Box>
        </Grid>
      </form>
        </div>
        <div style={{flex:3,padding:10,height:'80vh',overflow:"auto",backgroundColor:'#e5e5e5',minWidth:350}}>
        <Typography variant='h6'>Products</Typography>
        <div style={{display:"flex",alignItems:"center"}}> 
      <TextField
        label="Search Product"
        variant="outlined"
        size='small'
        value={productName}
        onChange={(e) => setProductName(e.target.value)}
        style={{ margin: '10px' }}
      />
      {/* <TextField
        label="Category"
        select
        variant="outlined"
        value={productCategory}
        onChange={(e) => setProductCategory(e.target.value)}
        style={{ margin: '10px' }}
      >
        {categories.map((category, index) => (
          <MenuItem key={index} value={category.name}>{category.name}</MenuItem>
        ))}
      </TextField> */}
      <Button variant="contained" color="primary" onClick={handleSearch}>
        Search
      </Button>
      <Button variant="outlined" color="primary" style={{marginLeft:10}} onClick={()=>{getProducts();setProductName('')}}>
        Cancel
      </Button>
      <Button variant="outlined" color="primary" style={{marginLeft:10}} onClick={()=>{exportCSV()}}>
        Download CSV
      </Button>
    </div>

<div style={{width:'100%',display:'flex',flexWrap:'wrap',justifyContent:"space-around"}}>
{products[selectedId]!=null&&<ProductModal product={products[selectedId]} open={modalOpen} onClose={handleCloseModal} />}
   
     {products.map((item,index)=>{
        return  <div key={index} style={{width:'25%',maxWidth:200,minWidth:170,margin:3,border:'0.3px solid #e5e5e5',backgroundColor:'#f8f8f8',position:'relative',display:'flex',flexDirection:'column'}}> 
       
        <div onClick={()=>{setSelectedId(index);handleOpenModal();

          
        }} style={{display:'flex',flex:1,justifyContent:'center',alignItems:'center'}}>
         <img src={item?.images?.length>0?item.images[0]:'https://ralfvanveen.com/wp-content/uploads/2021/06/Placeholder-_-Glossary.svg'} style={{ maxHeight: '100%', 
         width: 'auto', 
         maxWidth: '200px', 
         maxHeight:200,
         minWidth: '170px',
         objectFit: 'contain'}} />
             
        </div>
      {item.status &&     <Chip label={item.status} color={item.status!="New" ?"warning":"primary"} size="small" style={{padding:3,fontSize:12,borderRadius:10,fontWeight:'normal',position:'absolute',top:10,left:5,zIndex:10}}/>
}
{((item.originalPrice-item.price)/item.originalPrice *100) >= 1 && <div style={{padding:3,fontSize:12,borderRadius:10,backgroundColor:'#E53935',color:"white",opacity:1,fontWeight:'normal',position:'absolute',top:10,right:5,zIndex:10}}>
    
{((item.originalPrice-item.price)/item.originalPrice *100).toFixed(2)}%</div>}
<div style={{padding:3}}>
  <small style={{color:"blue"}}>{item.category}</small>

  <Typography style={{fontSize:14,lineHeight:'18px',fontWeight:'bolder'}}>
  {item.name}
  </Typography>
<div>
<Rating
      name="star-rating"
      value={4}
      style={{fontSize:20}}
      precision={0.5} // Set to 1 for whole stars, 0.5 for half stars
      // onChange={handleRatingChange}
    />
</div>
  <Typography style={{color:'green',fontWeight:'bold',fontSize:16}}>
Ksh {item.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
{' '}
{item.originalPrice!=null && parseInt(item.originalPrice)>parseInt(item.price) && <del style={{color:'grey',fontSize:14}}>{item?.originalPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</del>}
{' '}
{/* <small style={{color:'red'}}>29% off</small> */}

  </Typography>
  </div>
 
  <div style={{width:"100%",display:'flex',justifyContent:"space-between"}} >  
    <Button color='error' size='small' onClick={()=>handleDelete(item.id,item.images)}>Delete</Button>
    <Button size='small' onClick={()=>{
        var temp={...item};
temp.images=[];
setImagePreviews([])


        setProduct(temp);
        setEdit(item);
        // console.log(temp)
        if(item?.images?.length>0){
           setUploadedImages(item.images) 
        }
        // var t=item.category
        // var b = item.brand;
        // setCat(item.category)
    setShowNested(false)
    var t = item.category;
    var b = item.brand;
    setTimeout(() => {
      setShowNested(true);
      var temp={ ...item, category: t ,brand:b}
      setProduct(temp);
      
    }, 1000);
console.log(item)
        }}>Edit</Button>
    </div>

</div>
        
    })}
       {loading ? (
        <div style={{ textAlign: 'center', marginTop: 20 }}>Loading...</div>
      ) : (
        <div style={{ textAlign: 'center', marginTop: 20 ,width:'100%'}}>
          <Button variant='outlined' onClick={loadMoreProducts} style={{ padding: '10px 20px', fontSize: 16, cursor: 'pointer' }}>
           {loading?"Loading...":"Load More"}
          </Button>
        </div>
      )}


      <div style={{ height: 100 }}></div>
</div>
      </div>
    </div> </> }  </>
  );
};

const NestedSelect = ({ category,setValue,categories,product }) => {
    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedSubcategory, setSelectedSubcategory] = useState('');
    const [selectedLink, setSelectedLink] = useState('');
  useEffect(()=>{
    console.log(category)
    if(category.split('-').length==1){
        setSelectedCategory(category.split('-')[0].trim())
    }
    if(category.split('-').length==2){
        setSelectedCategory(category.split('-')[0].trim())
 setSelectedSubcategory(category.split('-')[1].trim());
        }
        if(category.split('-').length==3){
            setSelectedCategory(category.split('-')[0].trim())
            setSelectedSubcategory(category.split('-')[1].trim());
  setSelectedLink(category.split('-')[2].trim())
        }
  
  },[])
  useEffect(()=>{
    var temp=[];
    if(selectedCategory!=''){
        temp.push(selectedCategory)
    }
    if(selectedSubcategory!=''){
        temp.push(selectedSubcategory)
    }
    if(selectedLink!=''){
        temp.push(selectedLink)
    }
    setValue( temp.join(' - '))
  },[selectedCategory,selectedSubcategory,selectedLink])
    const handleCategoryChange = (event) => {
      setSelectedCategory(event.target.value);
      setSelectedSubcategory(''); // Reset subcategory on category change
      setSelectedLink(''); // Reset link on category change
    };
  
    const handleSubcategoryChange = (event) => {
      setSelectedSubcategory(event.target.value);
      setSelectedLink(''); // Reset link on subcategory change
    };
  
    const handleLinkChange = (event) => {
      setSelectedLink(event.target.value);
    };
  
    return (
    
        <Grid container spacing={2}>
          <Grid item xs={6}>
        {/* <FormControl style={{ minWidth: 200, marginTop: 10,marginRight:10 }}> */}
          {/* <InputLabel>Category</InputLabel> */}
          <Select
            size='small'
            fullWidth
            label="Category"
            value={selectedCategory}
            onChange={handleCategoryChange}
          >

            {categories?.map((category, index) => (
              <MenuItem key={index} value={category.name}>{category.name}</MenuItem>
            ))}
          </Select>
        {/* </FormControl> */}
        </Grid>
        <Grid item xs={6}>
        {selectedCategory && categories.find(cat => cat.name === selectedCategory)?.subcategories?.length>0 && (
        //   <FormControl style={{ minWidth: 200, marginTop: 10 }}>
            // <InputLabel>Subcategory</InputLabel>
            <Select
            size='small'
            fullWidth
            label="Subcategory"
              value={selectedSubcategory}
              onChange={handleSubcategoryChange}
            >
              {categories.find(cat => cat.name === selectedCategory)?.subcategories.map((subcategory, index) => (
                <MenuItem key={index} value={subcategory.name}>{subcategory.name}</MenuItem>
              ))}
            </Select>
        //   </FormControl>
        )}
  </Grid>
  <Grid item xs={6}>
        {selectedSubcategory && categories?.find(cat => cat.name === selectedCategory)?.subcategories
                .find(subcat => subcat.name === selectedSubcategory)?.subcategories?.length>0 && (
        //   <FormControl style={{ minWidth: 200, marginTop: 10 ,marginLeft:10}}>
            // <InputLabel>Link</InputLabel>
            <Select
              size='small'
              value={selectedLink}
              onChange={handleLinkChange}
              fullWidth
              label="Link"
            >
              {categories?.find(cat => cat.name === selectedCategory)?.subcategories
                .find(subcat => subcat.name === selectedSubcategory)?.subcategories.map((link, index) => (
                  <MenuItem key={index} value={link.name}>{link.name}</MenuItem>
                ))}
            </Select>
        //   </FormControl>
        )}
  </Grid> 
  {/* <Grid item xs={12}>
  {selectedCategory && selectedSubcategory && selectedLink && (
          <p>{selectedCategory} - {selectedSubcategory} - {selectedLink}</p>
        )}
        </Grid> */}
  </Grid>
       
   
    );
  };

  const ProductModal = ({ product, open, onClose }) => {
    const [selectedImage, setSelectedImage] = useState(product.images[0]);
  useEffect(()=>{
    setSelectedImage(product.images[0])
  },[product])
    const handleThumbnailClick = (image) => {
      setSelectedImage(image);
    };
  
    return (
      <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
        <DialogTitle sx={{fontWeight:'bold'}}>{product.name}</DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <img src={selectedImage} alt={product.name} style={{ width: '100%', borderRadius: '4px' ,maxHeight:350,objectFit:'contain'}} />
              <List style={{ display: 'flex', marginTop: '10px',justifyContent:'center' }}>
                {product.images.map((image, index) => (
                  <ListItem key={index} onClick={() => handleThumbnailClick(image)} style={{ cursor: 'pointer', padding: '4px',width:60 }}>
                    <img src={image} alt={`${product.name} ${index + 1}`} style={{ width: '50px', borderRadius: '4px', border: selectedImage === image ? '2px solid #000' : '2px solid transparent' }} />
                  </ListItem>
                ))}
              </List>
            </Grid>

            <Grid item xs={12} md={6}>
            
              <Typography variant="h6" sx={{fontWeight:'bold',color:"#43A047"}}>
                Price:Ksh {product.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </Typography>
              <Typography variant="body1" color="">
                Original Price: <del>{product.originalPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</del>
              </Typography>
              
              <Typography variant="body1" component={'div'} style={{ whiteSpace: 'pre-line', marginTop: '16px' }}>
                <Typography variant="h7" sx={{fontWeight:'bold'}}>
               Specification
              </Typography></Typography>
              <Typography variant="body1" color="">
                Category: {product.category}
              </Typography>
              <Typography variant="body1" color="">
                Size: {product.size}
              </Typography>
              <Typography variant="body1" color="">
                Color: {product.color}
              </Typography>
              <Typography variant="body1" color="">
                Quantity: {product.quantity}
              </Typography>
             
              <Typography variant="body1" component={'div'} style={{ whiteSpace: 'pre-line', marginTop: '16px' }}>
                <Typography variant="h7" sx={{fontWeight:'bold'}}>
               Description
              </Typography>
              {ReactHtmlParser(product.description) }
               
              </Typography>
            </Grid>
          </Grid>
          <div style={{padding:5}}>
<Typography variant='h5'>
            Other Details
          </Typography>
    
          {ReactHtmlParser(product.otherDetail) }
          </div>
          
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
  

export default ProductForm;
